import React, {useReducer} from 'react'
import CartContext from "./cart-context";

const defaultCartState = {
    items: [],
    totalAmount: 0
}

const cartReducer = (state, action) => {
    if(action.type === "ADD"){
        const updatedTotalAmount = state.totalAmount + action.item.price * action.item.amount;
        let updatedItems;


        const  existingCartItemIndex = state.items.findIndex((item) => {
            return item.id === action.item.id
        }) 

        const existingCartItem = state.items[existingCartItemIndex];

        if(existingCartItem){
           const  updatedItem = {
                ...existingCartItem,
                amount: existingCartItem.amount + action.item.amount
            };

            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;

        }else{
            updatedItems = state.items.concat(action.item); 
        }
    
        return {items: updatedItems, totalAmount: updatedTotalAmount};
    }
    else if(action.type === "REMOVE"){
        const existingCartItemIndex = state.items.findIndex(
            (item) => item.id === action.id
          );
          const existingItem = state.items[existingCartItemIndex];
          const updatedTotalAmount = state.totalAmount - existingItem.price;
          let updatedItems;
          if (existingItem.amount === 1) {
            updatedItems = state.items.filter(item => item.id !== action.id);
          } else {
            const updatedItem = { ...existingItem, amount: existingItem.amount - 1 };
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
          }
      
          return {
            items: updatedItems,
            totalAmount: updatedTotalAmount
          };
    } else if (action.type === 'CLEAR'){
        return defaultCartState;
    }

    return defaultCartState;
};


export default function CartProvider(props) {
    const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);

    const addItemToCartHandler = item => {
        dispatchCartAction({type: 'ADD', item: item});
    };

    const removeItemToCartHandler = id => {
        dispatchCartAction({type: 'REMOVE', id: id});
    };

    const clearCartHandler = () => {
        dispatchCartAction({type: 'CLEAR'});
    }

    const cartContext = {
        items: cartState.items,
        totalAmount: cartState.totalAmount,
        addItem: addItemToCartHandler,
        removeItem: removeItemToCartHandler,
        clearCart: clearCartHandler
    }

    return (
        <CartContext.Provider value={cartContext} >
            {props.children}
        </CartContext.Provider>
    )
}
